.table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .table tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  .table td:first-child,
  .table th:first-child {
    padding-left: 20px;
  }
  
  .table td:last-child,
  .table th:last-child {
    padding-right: 20px;
  }
  .customButton {
    color: grey;
  }